import "./index.scss";

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ImgWithFallback from "../../parts/ImgWithFallback";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";
import { generateUrlImage, IMAGE_SIZE } from "../../common/generate-url-image";
import { Carousel, IconButton, Media } from "@mbank-design/design-system/components";
import { ArrowLeft, ArrowRight, Cross } from "@mbank-design/design-system/icons";
import { IconButtonIconSize, IconButtonType, IconColor } from "@mbank-design/design-system/enums";

function VehicleGallery(props) {
  const [photos, setPhotos] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isShowSlider, setIsShowSlider] = useState(false);
  const carouselController = useRef(null);
  const thumbnailCarouselController = useRef(null);

  useEffect(() => {
    initPhotoData();

    if (!props.virtualGallerySRC) {
      showVehicleGallery();
    }
  }, []);

  useEffect(() => {
    props.checkIfShownSlider(isShowSlider);
  }, [isShowSlider])
  
  function initPhotoData() {
    const slides = [];
    const isWebpSupported = webpSupported();

    for (let [index, item] of props.photos.entries()) {
      if (item.includes(".jpg") || item.includes(".png")) {
        slides.push({
          fullSize: item,
          thumbnail: item
        });
        continue;
      }

      let smallSize = generateUrlImage(item, IMAGE_SIZE.SMALL);
      let fullSize = generateUrlImage(item, IMAGE_SIZE.LARGE);
      smallSize += isWebpSupported && props.isWebP ? ".webp" : ".jpg";
      fullSize += isWebpSupported && props.isWebP ? ".webp" : ".jpg";

      slides.push({
        fullSize: fullSize,
        thumbnail: smallSize
      });
    }

    setPhotos(slides);
  }

  function handleCarouselOnChange(slideId) {
    setActiveIndex(slideId);
    goToSlide(slideId, thumbnailCarouselController);
  }

  function goToSlide(slideId, controller) {
    let activeIndex = controller.current.__dangerouslyGetSwiperInstance.activeIndex;
    let difference = slideId - activeIndex;

    if (!difference) return;

    for (let index = 0; index < Math.abs(difference); index++) {
      if (difference > 0) {
        controller.current.slideNext();
      } else {
        controller.current.slidePrevious();
      }
    }

    setActiveIndex(slideId);
  }

  function carouselSlidePrevious() {
    carouselController.current.slidePrevious();
  }

  function carouselSlideNext() {
    carouselController.current.slideNext();
  }

  function showVehicleGallery() {
    setIsShowSlider(true);
    setActiveIndex(0);
  }

  function hideVehicleGallery() {
    setIsShowSlider(false);
    goToSlide(0, carouselController);
    setActiveIndex(null);
  }

  function handleThumbnailClick(index) {
    goToSlide(index, carouselController);

    if (!isShowSlider) {
      showVehicleGallery();
    }
  }

  const isMobile = Media.useMobileOnlyMedia()
  return (
    <div className="vehicle-gallery">
      <div className={`carousel ${isShowSlider ? "" : "hidden"}`}>
        <div className="button-close-container">
          <IconButton
            className="button-close icon-button button-transparent"
            iconComponent={Cross}
            buttonType={IconButtonType.TRANSPARENT}
            onClick={() => {
              hideVehicleGallery();
            }}
            iconColor={IconColor.SECONDARY_CONTRAST}
            iconSize={IconButtonIconSize.SIZE_16}
            />
          </div>
        <div className="pagination">
          <IconButton
            className="button-left icon-button button-transparent"
            iconComponent={ArrowLeft}
            buttonType={IconButtonType.TRANSPARENT}
            onClick={() => {
              carouselSlidePrevious();
            }}
            iconColor={IconColor.SECONDARY_CONTRAST}
            iconSize={IconButtonIconSize.SIZE_16}
          />
          <IconButton
            className="button-right icon-button button-transparent"
            iconComponent={ArrowRight}
            buttonType={IconButtonType.TRANSPARENT}
            onClick={() => {
              carouselSlideNext();
            }}
            iconColor={IconColor.SECONDARY_CONTRAST}
            iconSize={IconButtonIconSize.SIZE_16}
          />
        </div>
        {photos && photos.length > 0 && (
          <Carousel
            isSwipeableOnDesktop={true}
            isPaginationDynamic
            onChange={(activeIndex) => handleCarouselOnChange(activeIndex)}
            controller={(controller) => {
              carouselController.current = controller;
            }}
          >
            {photos.map((photo, index) => {
              return (
                <div className="slide" key={`slide${index}`}>
                  <ImgWithFallback src={photo.fullSize} fallback={photo.fullSize} />
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
      {props.virtualGallerySRC && (
        <div className={`virtual-car-container ${isShowSlider ? "hidden" : ""}`}>
          <iframe
            className="inner-player"
            title="virtual360"
            allowFullScreen={true}
            src={props.virtualGallerySRC}
            frameBorder="0"
          ></iframe>
        </div>
      )}
      <div className="thumbnails">
        {photos && photos.length > 0 && (
          <Carousel
            isPaginationDynamic
            visibleSlides={isMobile ? 2.7 : 5.4}
            controller={(controller) => {
              thumbnailCarouselController.current = controller;
            }}
            step={1}
            spacing={isMobile ? 8 : 16}
          >
            {photos.map((photo, index) => {
              return (
                <div
                  className={`thumbnail ${index === activeIndex ? "active" : ""}`}
                  key={`thumbnail${index}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <ImgWithFallback src={photo.thumbnail} fallback={photo.thumbnail} />
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className="content-container">
        {props.children}
      </div>
    </div>
  );
}

VehicleGallery.propTypes = {
  photos: PropTypes.array.isRequired,
  isWebP: PropTypes.bool.isRequired,
  virtualGallerySRC: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default VehicleGallery;
