import { PACKAGE } from "../../constants";

export const createPackagesDataForNewCars = (config, literal, isElectric, tabSelected) => {
  let packages = [];
  config.Configuration.forEach((item) => {
    switch (item.ServiceId) {
      case PACKAGE.INSURANCE:
        if (item.Visible) {
          packages.push({
            IconName: "ShieldTick",
            Name: "ubezpieczenie",
            Tooltip: literal?.calc_new_tooltip_insurance,
            Indent: false,
            CantBeDeactivated: false,
            SetSelfAsTrue: item.DefaultSelected,
            Disabled: !item.Enabled,
            Visible: item.Visible,
            Value: PACKAGE.INSURANCE
          });
        }
        break;
      case PACKAGE.ASSISTANCE:
        if (item.Visible) {
          packages.push({
            IconName: "CarPhoneHandle",
            Name: "assistance",
            Tooltip: literal?.calc_new_tooltip_assistance,
            Indent: false,
            CantBeDeactivated: false,
            SetSelfAsTrue: item.DefaultSelected,
            Disabled: !item.Enabled,
            Visible: item.Visible,
            Value: PACKAGE.ASSISTANCE,
            Price: config.Assistance
          });
        }
        break;
      case PACKAGE.BASIC_SERVICE:
        if (item.Visible) {
          packages.push({
            IconName: "Gear",
            Name: tabSelected === 1 ? "pakiet serwisowy Basic" : "koordynacja serwisu",
            Tooltip: tabSelected === 1 ? literal?.calc_new_tooltip_service_support_basic : literal.calc_used_tooltip_service_support,
            Indent: false,
            CantBeDeactivated: false,
            Dependency:tabSelected === 1 ? [
              {
                packageStateName: "pakiet_serwisowy_Comfort",
                valueRequired: true,
                action: "TOGGLE_SELF_WITH_DEP"
              }
            ] : null,
            SetSelfAsTrue: item.DefaultSelected,
            Disabled: !item.Enabled,
            Visible: item.Visible,
            Value: PACKAGE.BASIC_SERVICE,
            Price: config.TechnicalService ? config.TechnicalService : null
          });
        }
        break;
      case PACKAGE.COMFORT_SERVICE:
        if (item.Visible && tabSelected === 1) {
          packages.push({
            IconName: "Gear",
            Name: "pakiet serwisowy Comfort",
            Tooltip: literal?.calc_new_tooltip_service_support_comfort,
            Indent: false,
            CantBeDeactivated: false,
            Dependency: [
              {
                packageStateName: "pakiet_serwisowy_Basic",
                valueRequired: true,
                action: "TOGGLE_SELF_WITH_DEP"
              }
            ],
            SetSelfAsTrue: item.DefaultSelected,
            Disabled: !item.Enabled,
            Visible: item.Visible,
            Value: PACKAGE.COMFORT_SERVICE,
            Price: config.TechnicalService ? config.TechnicalService : null
          });
        }
        break;
      case PACKAGE.TYRES:
        if (item.Visible && config.Tyres.length) {
          packages.push({
            IconName: "Tire",
            Name: "pakiet Opony",
            Tooltip: literal?.calc_new_tooltip_tire_package,
            Indent: false,
            CantBeDeactivated: false,
            SetSelfAsTrue: item.DefaultSelected,
            Disabled: !item.Enabled,
            Visible: item.Visible,
            Value: PACKAGE.TYRES,
            Price: config.Tires ? config.Tires : null
          });
        }
        break;
      case PACKAGE.PETROL_CARD:
        if (item.Visible) {
          if (!isElectric) {
            packages.push({
              IconName: "CreditCard",
              Name: "karta paliwowa",
              Tooltip: literal?.calc_new_tooltip_fuel_card,
              Indent: false,
              CantBeDeactivated: false,
              SetSelfAsTrue: item.DefaultSelected,
              Disabled: !item.Enabled,
              Visible: item.Visible,
              Value: PACKAGE.PETROL_CARD,
              Price: config.PertrolCard ? config.PertrolCard : null
            });
            break;
          }
        } else {
          break;
        }
      default:
        break;
    }
  });
  return packages;
};

export function generateNewCalcAddPackagesStruct(literal, isElectric) {
  const packages = [
    {
      IconName: "ShieldTick",
      Name: "ubezpieczenie",
      Tooltip: literal?.calc_new_tooltip_insurance,
      Indent: false,
      CantBeDeactivated: true,
      SetSelfAsTrue: true,
      Disabled: true,
      Value: PACKAGE.INSURANCE
    },
    {
      IconName: "CarPhoneHandle",
      Name: "assistance",
      Tooltip: literal?.calc_new_tooltip_assistance,
      Indent: false,
      Value: PACKAGE.ASSISTANCE
    },
    {
      IconName: "Gear",
      Name: "pakiet serwisowy Basic",
      Tooltip: literal?.calc_new_tooltip_service_support_basic,
      Dependency: [
        {
          packageStateName: "pakiet_serwisowy_Comfort",
          valueRequired: true,
          action: "TOGGLE_SELF_WITH_DEP"
        }
      ],
      Indent: false,
      CantBeDeactivated: true,
      SetSelfAsTrue: true,
      Value: PACKAGE.BASIC_SERVICE
    },
    {
      IconName: "Gear",
      Name: "pakiet serwisowy Comfort",
      Tooltip: literal?.calc_new_tooltip_service_support_comfort,
      Indent: false,
      Value: PACKAGE.COMFORT_SERVICE,
      AddStar: true,
      SetSelfAsTrue: false,
      CantBeDeactivated: true,
      Dependency: [
        {
          packageStateName: "pakiet_serwisowy_Basic",
          valueRequired: true,
          action: "TOGGLE_SELF_WITH_DEP"
        }
      ]
    },
    {
      IconName: "Tire",
      Name: "pakiet Opony",
      Tooltip: literal?.calc_new_tooltip_tire_package,
      Indent: false,
      Value: PACKAGE.TYRES
    }
  ];

  if (!isElectric) {
    packages.push({
      IconName: "CreditCard",
      Name: "karta paliwowa",
      Tooltip: literal?.calc_new_tooltip_fuel_card,
      Indent: false,
      Value: PACKAGE.PETROL_CARD
    });
  }

  return packages;
}

export function checkNewCalcPackageDependencyAndVisibilities(
  triggeredPackage,
  packageState,
  additionalPackageArr,
  longTimeRentValuesDTO
) {
  // triggeredPackage - package which was clicked
  // packageState - object with the states of the packages
  // additionalPackageArr - raw array with the packages

  let currentState = {...packageState};

  for (let pkg of additionalPackageArr) {
    let currentStateObj = currentState[pkg.Name.split(' ').join('_')];

    // check if value based on period is correct
    let currentlySelectedPeriod = longTimeRentValuesDTO.Periods.find(x => x.Status === true).Value;

    if (pkg.Value === 1) {
      let currentlySelectedMileage = longTimeRentValuesDTO.Mileages.find(x => x.Status === true).Value;

      let price = pkg.Price.find(x => x.Period === currentlySelectedPeriod).Mileages.find(x => x.MileageTotal === currentlySelectedMileage).Value;
      if (currentStateObj.Price !== price) {
        currentStateObj.Price = price;
      }
    }

    if (Array.isArray(pkg.Price) && pkg.Value !== 1) {
      let correctPrice = pkg.Price.find(x => x.Period === currentlySelectedPeriod).Value;
      if (currentStateObj.Price !== correctPrice) {
        currentStateObj.Price = correctPrice;
      }
    }

    // check for dependencies
    if (pkg.Dependency && pkg.Dependency.length > 0 && triggeredPackage.Value !== currentStateObj.Value) {
      for (let dep of pkg.Dependency) {
        let depStateObject = currentState[dep.packageStateName];
        switch (dep.action) {
          case 'SET_SELF_FALSE':
            if (depStateObject.isChecked === dep.valueRequired) {
              currentStateObj.isChecked = false;
            }
            if (!currentStateObj.isChecked) {
              depStateObject.isChecked = true;
            }
            break;
          case 'SET_SELF_TRUE':
            if (depStateObject.isChecked === dep.valueRequired) {
              currentStateObj.isChecked = true;
            }
            if (!currentStateObj.isChecked) {
              depStateObject.isChecked = true;
            }
            break;
          default:
            break;
        }
      }
    }

    // check for visibility
    let isVisible = true;
    if (pkg.Visibility && pkg.Visibility.length > 0) {
      for (let rule of pkg.Visibility) {
        currentState[rule.packageStateName].isChecked !== rule.valueRequired ? isVisible = false : void 0;
      }
    }
    if (currentStateObj.isVisible !== isVisible) {
      currentStateObj.isVisible = isVisible;
      currentStateObj.isChecked = false;
    }
  }
  return currentState;
}

/**
 * function computes monthly net price of long time rent for new vehicles
 * @param {{CantBeDeactivated: boolean, DefaultSelectedForLeasing: boolean, DefaultSelectedForRent: boolean, IconName: string, Indent: boolean, Name: string, Order: number, Price: number, SetSelfAsTrue: boolean, Tooltip: string, Value: number, Visibility: {     packageStateName: string     valueRequired: boolean }}[]} packages - additional service items
 * @param {number[]} selectedPackages - indexes (values) of selected additional service items
 * @param {number} monthlyBaseNetPrice - monthly base net price of rent for currently selected period, mileage per year and OW.
 *                                       price from rent period should not include price of any additional service items (packages)
 * @returns {number} total net price for monthly rent
 */
export function computeNewVehicleRentMonthlyNetPrice(packages, selectedPackages, monthlyBaseNetPrice) {
  let totalPrice = monthlyBaseNetPrice;
  Object.values(packages).forEach(pkg => {
    if(selectedPackages.includes(pkg.Value)) {
      totalPrice += pkg.Price;
    }
  });

  return totalPrice;
}


export function generateNewCalcInitialCalcForm(calcOptionsRaw, selectedOptions) {
  let obj = {
    LongTimeRentCalculatorValuesDTO: {
      Periods: [],
      Mileages: [],
      Ows: []
    },
  };
  for (let item of calcOptionsRaw.RentPeriods) {
    if (selectedOptions) {
      // turn off default selects and insert the stuff from selectedOptions
      calcOptionsRaw.RentPeriods.find(x => x.Period.Status === true).Period.Status = false;
      let period = calcOptionsRaw.RentPeriods.find(x => x.Period.Value == selectedOptions.Period);
      period.Period.Status = true;

      period.Ows.find(x => x.Ow.Status === true).Ow.Status = false;
      let ow = period.Ows.find(x => x.Ow.Value == selectedOptions.Ow);
      ow.Ow.Status = true;

      // TODO - sometimes all options from Mileage are nulls, change code below when fixed
      let oldMileage = ow.Mileages.find(x => x.Status === true);
      if (oldMileage) {
        oldMileage.Status = false;
      }
      ow.Mileages.find(x => x.Value == selectedOptions.Mileage).Status = true;
    }

    obj.LongTimeRentCalculatorValuesDTO.Periods.push({...item.Period});

    if (item.Period.Status === true) {
      for (let el of item.Ows) {
        obj.LongTimeRentCalculatorValuesDTO.Ows.push({...el.Ow});

        if (el.Ow.Status === true) {
          obj.LongTimeRentCalculatorValuesDTO.Mileages = [...el.Mileages];
        }
      }
    }
  }
  return obj;
}
