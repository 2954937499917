import { PACKAGE } from "../../constants";

export function modifyUsedCalcPackages(arr, literal, isElectric, IsNewVehicle) {
  // serwis opon
  let index = arr.findIndex(el => el.Value === PACKAGE.TYRES);
  arr.splice(index, 1);

  // karta paliwowa
  if (isElectric) {
    let index = arr.findIndex(el => el.Value === PACKAGE.PETROL_CARD);
    arr.splice(index, 1);
  }

  for (let item of arr) {
    switch (item.Value) {
      case PACKAGE.PETROL_CARD:
        // karta paliwowa
        item.Name = item.Name.toLowerCase();
        item.Indent = false;
        item.SetSelfAsTrue = false;
        item.CantBeDeactivated = false;
        item.Visibility = null;
        item.Tooltip = literal && literal.calc_used_tooltip_fuel_card;
        break;
      case PACKAGE.SERVICE_COORDINATION:
        // obsługa techniczna
        item.Name = "koordynacja serwisu";
        item.Indent = false;
        item.SetSelfAsTrue = true;
        item.CantBeDeactivated = true;
        item.Visibility = null;
        item.Tooltip = literal && literal.calc_used_tooltip_service_support;
        item.Disabled = true;
        break;
      case PACKAGE.ASSISTANCE:
        // assistance
        item.Name = item.Name.toLowerCase();
        item.Indent = false;
        item.SetSelfAsTrue = false;
        item.CantBeDeactivated = false;
        item.Visibility = null;
        item.Tooltip = literal && literal.calc_used_tooltip_assistance;
        break;
      case PACKAGE.INSURANCE:
        // ubezpieczenie
        item.Name = item.Name.toLowerCase();
        item.Indent = false;
        item.Disabled = IsNewVehicle;
        item.SetSelfAsTrue = IsNewVehicle;
        item.CantBeDeactivated = false;
        item.Visibility = null;
        item.Tooltip = literal && literal.calc_used_tooltip_insurance;
        break;
    }
  }
  return arr;
}
