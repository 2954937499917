import React from "react";
import { Helmet } from "react-helmet";
import "./index.scss";
import { Link, withRouter } from "react-router-dom";
import { Icon, Text } from "@mbank-design/design-system/components";
import {
  FontColor,
  FontSize,
  FontWeight,
  IconColor,
} from "@mbank-design/design-system/design-tokens/general/typography";
import { ArrowLeft, Cross } from "@mbank-design/design-system/icons";
import ContactFormForm from "../../modules/ContactFormSectionModal";
import {
  getLeaseDetailsData,
  getCalculatorProperties,
  getLongTimeRentPrice,
  getLeasingPrice,
  getNewDetailsData,
} from "../../../services";
import CarTileSummary from "../../modules/CarTileSummary";
import BaseCalculator from "../../extendables/calculator";
import { modifyUsedCalcPackages } from "../../common/modify-used-calc-packages";
import { endpointGuard } from "../../../scripts/commons/guard/endpoint-guard";
import {
  generateNewCalcAddPackagesStruct
} from "../../common/new-calc-additional-packages";
import { extraInsuranceTooltipText, PACKAGE } from "../../../constants";
import { isWebpSupported as webpSupported } from "../../../scripts/commons/support-webp";
import { generateUrlImage, IMAGE_SIZE } from "../../common/generate-url-image";
import CertificateSummary from "../../modules/CertificateSummary";
import {
  getInsurancePackagePriceBySelectedPeriod
} from "../../common/calculator";

class ContactForm extends BaseCalculator {
  state = {
    CalculatorProperties: null,
    OfferDetails: null,
    PreviewPhoto: null,
    backgroundPhoto: "",
    isReserved: false,
    auctionId: null,
    isCertificateSubmitted: false,
    FinancingMethod: null,
  };
  componentDidMount() {
    window.scrollTo({ top: 0 });
    let encodedParamObject = this.getEncodedParams();
    const financingMethod = Number(encodedParamObject.FinancingMethod);

    if (encodedParamObject?.AuctionId && encodedParamObject?.AfterLease && encodedParamObject?.FinancingMethod) {
      if (encodedParamObject.AfterLease === "true") {
        getLeaseDetailsData({ id: encodedParamObject.AuctionId }).then(
          (res) => {
            if (res.status >= 200 && res.status <= 299) {
              if (res.data.IsCashAvailable === false && encodedParamObject.FinancingMethod === "3") {
                window.location.href = "/404";
              }
              this.setState(
                {
                  OfferDetails: res.data,
                  AuctionId: encodedParamObject.AuctionId,
                  isAfterLease: encodedParamObject.AfterLease === "true",
                  FinancingMethod: financingMethod
                },
                () => {
                  this.handleCalcData(encodedParamObject);

                  const backgroundImage = res.data.Photos[8] ? res.data.Photos[8] : res.data.Photos[res.data.Photos.length - 1];

                  this.setBackgroundImage(backgroundImage);
                  this.getPreviewPhoto();
                }
              );
            } else {
              endpointGuard(res.data.ErrorCode);
            }
          }
        );
      } else {
        getNewDetailsData({ id: encodedParamObject.AuctionId }).then(res => {
          this.setState(
            {
              OfferDetails: res.data,
              AuctionId: encodedParamObject.AuctionId,
              isAfterLease: encodedParamObject.AfterLease === "true",
              FinancingMethod: financingMethod
            },
            () => {
              this.handleCalcData(encodedParamObject, res.data);

              const backgroundImage = res.data.PhotosVC ?
                res.data.PhotosVC[8] ? res.data.PhotosVC[8] : res.data.PhotosVC[res.data.PhotosVC.length - 1] :
                res.data.Photos[8] ? res.data.Photos[8] : res.data.Photos[res.data.Photos.length - 1];

              this.setBackgroundImage(backgroundImage);
              this.getPreviewPhoto();
            }
          );
        });
      }
    }
  }

  handleCertificateSubmitted = () => {
    this.setState({isCertificateSubmitted:!this.isCertificateSubmitted})
  }

  handleCalcData(encodedParamObject, newVehicleDetails = null) {
    const offerDetails = this.state.OfferDetails;
    const selectedPeriod = Number.parseInt(encodedParamObject.Period);
    const selectedMileage = Number.parseInt(encodedParamObject.Mileages);
    const selectedOws = Number.parseInt(encodedParamObject.Ows);
    const selectedRvs = Number.parseInt(encodedParamObject.Rvs);

    let selectedCarDetailsPeriod = [];
    let selectedCarDetailsOws = [];
    let selectedCarDetailsMileage = {};
    let selectedCarDetailsRvs = {};
    let objectArray = {};
    
    getCalculatorProperties().then(({ data }) => {
      if (this.state.FinancingMethod === 1) {
        if (encodedParamObject.AfterLease === "true") {
          selectedCarDetailsPeriod = data.RentPeriods.find(rentPeriod => rentPeriod.Period.Value === selectedPeriod);
        } else {
          selectedCarDetailsPeriod = offerDetails.Calculator.RentPeriods.find(rentPeriod => rentPeriod.Period.Value === selectedPeriod);
        }

        selectedCarDetailsOws = selectedCarDetailsPeriod?.Ows.find(ows => ows.Ow.Value === selectedOws);
        selectedCarDetailsMileage = selectedCarDetailsOws?.Mileages.find(mileage => mileage.Value === selectedMileage);

        objectArray = {
          ...objectArray,
          Ows: selectedCarDetailsOws.Ow,
          Periods: selectedCarDetailsPeriod.Period,
          Mileages: selectedCarDetailsMileage
        };
      } else if (this.state.FinancingMethod === 2) {
        selectedCarDetailsPeriod = data.LeasingPeriods.find(rentPeriod => rentPeriod.Period.Value === selectedPeriod);
        selectedCarDetailsOws = selectedCarDetailsPeriod?.Ows.find(ows => ows.Ow.Value === selectedOws);
        selectedCarDetailsRvs = selectedCarDetailsOws?.Rvs.find(rvs => rvs.Value === selectedRvs);

        objectArray = {
          ...objectArray,
          Ows: selectedCarDetailsOws.Ow,
          Periods: selectedCarDetailsPeriod.Period,
          Rvs: selectedCarDetailsRvs
        };
      }
      
      //For after leasing cars or new car with leasing
      if (
        encodedParamObject.AfterLease === "true" ||
        (this.state.FinancingMethod === 2 && encodedParamObject.AfterLease === "false")
      ) {
        const additionalPackages = modifyUsedCalcPackages(
          data.AdditionalServiceItems,
          this.props.literals,
          this.state.OfferDetails.IsElectric
        );

        // set proper additionalPackageData
        for (let item of additionalPackages) {
          if (encodedParamObject["Package" + item.Value]) {
            item.isChecked =
              encodedParamObject["Package" + item.Value] === "true";
          }
        }

        this.emitFilters(encodedParamObject, additionalPackages, objectArray);
      } else {
        //For new cars and rent
        let additionalPackagesForNewCarRent = generateNewCalcAddPackagesStruct(this.props.literals, this.state.OfferDetails.IsElectric);
        let packages = [...additionalPackagesForNewCarRent];
        
        //set package prices
        packages.forEach((pkg) => {
          switch (pkg.Value) {
            case PACKAGE.INSURANCE:
              pkg.Price =
                offerDetails.Calculator.Insurance.find((ins) => ins.Period === selectedPeriod).Value ||
                this.state.insuranceFormulaPrice;
              break;
            case PACKAGE.ASSISTANCE:
              pkg.Price = offerDetails.Calculator.RentAdditions.Assistance;
              break;
            case PACKAGE.BASIC_SERVICE:
              let rentManagementBasicForCurrentlySelectedPeriod =
                offerDetails.Calculator.RentAdditions.Rent_ManagementBasic.find(
                  (rmb) => rmb.Period === selectedPeriod
                );
              let rentManagementBasicForCurrentlySelectedPeriodAndMileage =
                rentManagementBasicForCurrentlySelectedPeriod
                  ? rentManagementBasicForCurrentlySelectedPeriod.Mileages.find(
                    (mlg) => mlg.MileageTotal === selectedMileage
                  )
                  : null;
              pkg.Price = rentManagementBasicForCurrentlySelectedPeriodAndMileage
                ? rentManagementBasicForCurrentlySelectedPeriodAndMileage.Value
                : null;
              break;
            case PACKAGE.COMFORT_SERVICE:
              let rentManagementComfortForCurrentlySelectedPeriod =
                offerDetails.Calculator.RentAdditions.Rent_ManagementComfort.find(
                  (rmb) => rmb.Period === selectedPeriod
                );
              let rentManagementComfortForCurrentlySelectedPeriodAndMileage =
                rentManagementComfortForCurrentlySelectedPeriod
                  ? rentManagementComfortForCurrentlySelectedPeriod.Mileages.find(
                    (mlg) => mlg.MileageTotal === selectedMileage
                  )
                  : null;
              pkg.Price = rentManagementComfortForCurrentlySelectedPeriodAndMileage
                ? rentManagementComfortForCurrentlySelectedPeriodAndMileage.Value
                : null;
              break;
            case PACKAGE.TYRES:
              pkg.Price = offerDetails.Calculator.RentAdditions.Tyres.find(
                (tyre) => tyre.Period === selectedPeriod
              ).Value;
              break;
            case PACKAGE.PETROL_CARD:
              pkg.Price = offerDetails.Calculator.RentAdditions.PetrolCard;
              break;
          }
        });

        let selectedPackages = Object.keys(encodedParamObject).filter(key => key.includes("Package") && encodedParamObject[key] === "true")
          .map(packageValue =>  packageValue.replace("Package",""));

        Object.values(packages).forEach(pkg => {
          if (selectedPackages.includes(pkg.Value.toString())) {
            pkg.isChecked = true;
          }
        });

        this.emitFilters(encodedParamObject, packages, objectArray);

        this.setState({
          CalculatorProperties: {
            ...this.state.CalculatorProperties,
            AdditionalServices: packages
          }
        });
      }
    });
  }

  getInsuranceCurrentPrice() {
    const packages = [...this.state.CalculatorProperties.AdditionalServices];
    let insurancePrice = 0;

    if (this.state.isAfterLease) {
      insurancePrice = this.state.CalculatorProperties?.CalculationResult?.InsurancePriceInfo;
    } else {
      if (this.state.FinancingMethod === 1) {
        insurancePrice = getInsurancePackagePriceBySelectedPeriod(this.state.OfferDetails, this.state.CalculatorProperties.Periods);
      } else if (this.state.FinancingMethod === 2) {
        if (this.state.OfferDetails.Calculator.LeasingPeriods && this.state.OfferDetails.Calculator.LeasingPeriods.length) {
          insurancePrice = getInsurancePackagePriceBySelectedPeriod(this.state.OfferDetails, this.state.CalculatorProperties.Periods);
        } else {
          insurancePrice = this.state.CalculatorProperties?.CalculationResult?.InsurancePriceInfo;
        }
      }
    }

    packages.find((x) => x.Value === PACKAGE.INSURANCE).Price = insurancePrice;
    this.setState({
      ...this.state,
      CalculatorProperties: {
        ...this.state.CalculatorProperties,
        AdditionalServices: packages
      }
    });
  }

  emitFilters(encodedParamObject, packages, selectedOptions) {
    let filterToSend = {
      FinancingMethod: this.state.FinancingMethod,
      Filters: {
        AdditionalServices: [],
        Price: this.state.OfferDetails.TotalPriceBrutto,
        HP: this.state.OfferDetails.HP,
        isNew: !this.state.isAfterLease,
        isElectric: this.state.OfferDetails.IsElectric,
        isTruck: this.state.OfferDetails.IsTruck,
        AuctionId: Number(encodedParamObject.AuctionId)
      }
    };

    for (let name of Object.keys(selectedOptions)) {
      filterToSend.Filters[name.substring(0, name.length - 1)] =
        selectedOptions[name].Value;
    }

    for (let pkg of packages) {
      if (pkg.isChecked) {
        filterToSend.Filters.AdditionalServices.push(pkg.Value);
      }
    }

    if (filterToSend.FinancingMethod === 1) {
      filterToSend.Filters.CalculatorLongTermTypeId =
        this.state.OfferDetails.CalculatorLongTermTypeId;

      getLongTimeRentPrice(filterToSend.Filters).then((res) => {
        const indexOfInsurance = packages.findIndex(el => (el.Value === PACKAGE.INSURANCE));
        let packagesExtraTooltip = JSON.parse(JSON.stringify(packages));
        if (this.state.OfferDetails.TotalPriceNetto > 250000) {
          packagesExtraTooltip[indexOfInsurance].Tooltip +=
            extraInsuranceTooltipText;
        }
        if(res.data.InsuranceAdditionalText){
          packagesExtraTooltip[indexOfInsurance].Tooltip += ` ${res.data.InsuranceAdditionalText}`
        }
        this.setState(
          {
            CalculatorProperties: {
              AdditionalServices: packagesExtraTooltip,
              AuctionId: encodedParamObject.AuctionId,
              CalculationResult: res.data,
              FinancingMethod: {
                Name: "najem",
                Status: "true",
                Value: filterToSend.FinancingMethod
              },
              ...selectedOptions
            },
          },
          () => {
            this.getInsuranceCurrentPrice();
          }
        );
      });
    } else if (filterToSend.FinancingMethod === 2) {
      filterToSend.Filters.CalculatorLeasingTypeId =
        this.state.OfferDetails.CalculatorLeasingTypeId;

      getLeasingPrice(filterToSend.Filters).then((res) => {
        const indexOfInsurance = packages.findIndex(el => (el.Value === PACKAGE.INSURANCE));
        let packagesExtraTooltip = JSON.parse(JSON.stringify(packages));

        if (this.state.OfferDetails.TotalPriceNetto > 250000) {
          packagesExtraTooltip[indexOfInsurance].Tooltip +=
            extraInsuranceTooltipText;
        }
        if (res.data.InsuranceAdditionalText ) {
          packagesExtraTooltip[indexOfInsurance].Tooltip += ` ${res.data.InsuranceAdditionalText}`
        }
        
        this.setState(
          {
            CalculatorProperties: {
              AdditionalServices: packagesExtraTooltip,
              AuctionId: encodedParamObject.AuctionId,
              CalculationResult: res.data,
              FinancingMethod: {
                Name: "leasing",
                Status: "true",
                Value: filterToSend.FinancingMethod
              },
              ...selectedOptions
            },
          },
          () => {
            this.getInsuranceCurrentPrice();
          }
        );
      });
    } else if (filterToSend.FinancingMethod === 3) {
      this.setState(
        {
          CalculatorProperties: {
            AdditionalServices: packages,
            AuctionId: encodedParamObject.AuctionId,
            CalculationResult: null,
            FinancingMethod: {
              Name: "gotówka",
              Status: "true",
              Value: filterToSend.FinancingMethod
            },
            ...selectedOptions
          }
        });
    }
  }

  getPreviewPhoto() {
    const isWebpSupported = webpSupported();
    
    let mainPhotoUrl = generateUrlImage(this.state.OfferDetails.MainPhotoUrl, IMAGE_SIZE.LARGE);
    mainPhotoUrl += isWebpSupported && this.state.OfferDetails.IsWebP && mainPhotoUrl.includes('.webp') ? 
      '.webp' : mainPhotoUrl.includes('.png') || mainPhotoUrl.includes('.jpg') ? '' : '.jpg';
     
    this.setState({ PreviewPhoto: mainPhotoUrl });
  }

  setBackgroundImage(imageUrl) {
    const isWebpSupported = webpSupported();
    
    let backgroundPhoto = generateUrlImage(imageUrl, IMAGE_SIZE.LARGE);
    backgroundPhoto += isWebpSupported && this.state.OfferDetails.IsWebP ? '.webp' : '.jpg';

    this.setState({ backgroundPhoto: backgroundPhoto });
  }

  getEncodedParams() {
    try {
      let urlParams = this.props.urlObject
      let parsedParams = new URLSearchParams(urlParams);
      let paramObject = {};
      for (let obj of parsedParams) {
        paramObject[obj[0]] = obj[1];
      }
      return paramObject;
    } catch (err) {
    }
  }

  submit() {
    window.location.href = `/uzywane/contact-form-summary/${
      window.location.pathname.split("/")[2]
    }`;
  }
  
  render() {
    let offerDetails = this.state.OfferDetails;
    let calculatorData = this.state.CalculatorProperties;
    let previewPhoto = this.state.PreviewPhoto;
    const isWebpSupported = webpSupported();

    return (
      offerDetails && calculatorData && previewPhoto &&
      <div
        className={
          this.props.location.search
            ? "contact-form-container"
            : "contact-form-container contact-form-container--default"
        }
      >
        {this.props.location.search ? (
          <Helmet>
            <title>Formularz kontaktowy | mAuto.pl</title>
            <meta name="robots" content="noindex,nofollow"></meta>
          </Helmet>
        ) : (
          <Helmet>
            <title>Formularz kontaktowy | mAuto.pl</title>
            <meta
              name="description"
              content="Formularz konatkowy serwisu mauto.pl"
            />
          </Helmet>
        )}
        <div className="contact-form__header">
          {/* <span></span> */}
          <Text as="h2">zapytaj o ofertę</Text>
          {/* <Icon iconComponent={Cross} primaryColor={"secondary_contrast"}/> */}
        </div>
        <div className="content-wrapper">
          <div className="contact-form-title-container">
            {offerDetails && (
                <Text
                  as="h1"
                  className="title"
                  fontWeight={FontWeight.WEIGHT_600}
                  fontSize={FontSize.SIZE_40}
                  color={"white30"}
                >
                  {this.props.literals && this.props.literals.cfp_title}
                </Text>
              )}
            {!offerDetails && (
              <Text
                as="h1"
                className="title"
                fontWeight={FontWeight.WEIGHT_600}
                fontSize={FontSize.SIZE_40}
                color={FontColor.zeusBlackUI}
              >
                zapytaj o ofertę
              </Text>
            )}

            <div className="contact-form">
              {this.state.isCertificateSubmitted ?
                <CertificateSummary
                certificate={offerDetails?.Certificate}
                /> :
                <ContactFormForm
                auctionID={this.state.AuctionId}
                properties={this.state.CalculatorProperties || null}
                offerData={offerDetails}
                isReserved={this.state.isReserved}
                isAfterLease={this.state.isAfterLease}
                isDefaultContact={!this.state.AuctionId}
                handleCertificateSubmitted={this.handleCertificateSubmitted}
                calcCurrentPrices={this.props.calcCurrentPrices}
                />
              }
            </div>
          </div>
          {!this.state.isReserved && this.state.AuctionId && (
            <div className="summary-tile">
              <CarTileSummary
                chips={this.props.chips}
                pageType={this.props.pageType}
                isAfterLease={this.state.isAfterLease}
                offerData={offerDetails}
                previewPhoto={previewPhoto}
                calcPrice={this.props.calcPrice}
                calculatorData={calculatorData}
                financingMethod={this.state.FinancingMethod}
                calcCurrentPrices={this.props.calcCurrentPrices}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(ContactForm);
