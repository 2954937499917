import React from "react";
import { Media, Text, Button } from "@mbank-design/design-system/components";
import { ButtonColor, ButtonType, FontColor } from "@mbank-design/design-system/enums";
import "./index.scss";
export const SpecificTilesEnum = {
  FindBeyondTile: 1,
  NoFindTeslaTile: 2,
  NoFindTile: 3
};
const SpecificTile = ({ tileType }) => {
  const isDesktop = Media.useDesktopMedia();
  const handleClick = () => {
    sessionStorage.setItem("specialTile", tileType);
    setTimeout(() => {
      window.location.href = "/formularz-kontaktowy";
    }, 0);
  };
  return (
    <div className={`specific-card-tile tile-type-${tileType}`}>
      <Text as="h2" marginBottom={16} color={FontColor.LIGHT}>
        {tileType === SpecificTilesEnum.FindBeyondTile
          ? "znalazłeś auto poza serwisem mAuto.pl?"
          : tileType === SpecificTilesEnum.NoFindTeslaTile
          ? "nie znalazłeś Tesli na mAuto?"
          : tileType === SpecificTilesEnum.NoFindTile
          ? "nie znalazłeś auta na mAuto?"
          : ""}
      </Text>
      <Text as="p" marginBottom={32} color={FontColor.LIGHT}>
        {tileType === SpecificTilesEnum.FindBeyondTile
          ? "sfinansujemy je dla Ciebie"
          : tileType === SpecificTilesEnum.NoFindTeslaTile
          ? "skontaktuj się z nami, a my znajdziemy dla Ciebie wybarzoną Teslę!"
          : tileType === SpecificTilesEnum.NoFindTile
          ? "skontaktuj się z nami, a my znajdziemy dla Ciebie wymarzony samochód!"
          : ""}
      </Text>
      <Button
        buttonType={ButtonType.PRIMARY}
        color={isDesktop ? ButtonColor.PRIMARY_CONTRAST : ButtonColor.SECONDARY_CONTRAST}
        onClick={handleClick}
      >
        <span>zapytaj o ofertę</span>
      </Button>
    </div>
  );
};
export default SpecificTile;

