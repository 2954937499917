import React, { Component } from "react";
import "./index.scss";
import MainContactSection from "../../modules/MainContactSection";
import AdviseSection from "../../modules/AdviseSection";
import CarOfferTile from "../../modules/CarOfferTile";
import ListBanner from "../../modules/ListBanner";
import { getAfterLeaseVehiclesOffers, getNewVehiclesOffers, getOffersCount } from "../../../services";
import { MobileFiltersContext } from "../../../scripts/commons/contexts";
import { Text } from "@mbank-design/design-system/components";
import { FontColor, FontSize, LineHeight } from "@mbank-design/design-system/design-tokens/general/typography";
import { LoadingSpinner } from "../../parts/LoadingSpinner";
import { changeSelectSortBy } from "../../../scripts/commons/sorting";
import { Link, withRouter } from "react-router-dom";
import ContactIcons from "../../parts/ContactIcons";
import { literalCreator } from "../../../literals/literals";
import { setCampaignData } from "../../common/campaign-data-setter";
import { endpointGuard } from "../../../scripts/commons/guard/endpoint-guard";
import { Helmet } from "react-helmet";
import ListPageHelmet from "../../parts/ListPageHelmet";
import HeaderTextSection from "../../modules/HeaderTextSection";
import QuestionTextSectionNewCar from "../../modules/QuestionTextSectionNewCar";
import QuestionTextSectionLeaseCar from "../../modules/QuestionTextSectionLeaseCar";
import MobileHeaderTextSection from "../../modules/MobileHeaderTextSection";
import MobileHeaderTitle from "../../modules/MobileHeaderTitle";
import DynamicQuestionTextSection from "../../modules/DynamicQuestionTextSection";
import QuestionTextSectionElectricCar from "../../modules/QuestionTextSectionElectricCar";
import {
  imitationClickForCloseAllTooltips,
  removeDuplicatesByKeyFromArrayOfObject
} from "../../../scripts/commons/common";
import MobileFilters from "../../modules/MobileFilters";
import SpecificTile, { SpecificTilesEnum } from "../../parts/SpecificTile";

const referenceToOffers = React.createRef();
const referenceToSortBar = React.createRef();

class ListPage extends Component {
  state = {
    tileView: localStorage.getItem("tileView") || "two",
    newCarsData: [],
    afterLeaseCarsData: [],
    isLoadingCars: true,
    newCarsPage: 1,
    afterLeaseCarsPage: 1,
    endingAuctionsPage: 1,
    offerFiltersData: null,
    carsCount: 18,
    carList: [],
    carsDisplayFrom: 0,
    isFirstScrollRender: true,
    selectedSortingValue: "0",
    searchedNewCarsCount: 0,
    searchedALCarsCount: 0,
    searchedAllCarsCount: 0,
    isCompany: false,
    currentFilters: {},
    isShowFiltersEnabled: false,
    countFilters: 0,
    carStatus: null,
    width: window.innerWidth
  };

  componentDidMount() {
    this.redirectIfIsBodyTypeOrTypeParam();
    setCampaignData();
    this.setState({ offerFiltersData: this.props.offersFiltersDataFromApi });
    literalCreator.getLiterals().then((res) => {
      this.setState({ literals: literalCreator.data });
    });
    this.updateIdList();

    const carsCount = localStorage.getItem("carsCount");
    const previousViewedCarId = localStorage.getItem("previousViewedCarId");
    if (carsCount && previousViewedCarId) {
      this.setState({
        carsCount: Number(carsCount)
      });
    } else {
      window.history.scrollRestoration = "manual";
      localStorage.setItem("carsCount", this.state.carsCount);
    }
    document.addEventListener("scroll", this.scrollEventListenerToLoadMoreCars);
    window.addEventListener('resize', this.handleResize);
  }

  carListWithoutSoring = [];

  componentWillUnmount() {
    document.removeEventListener("scroll", this.scrollEventListenerToLoadMoreCars);
    this.setDefaultMetaTags();
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      this.redirectIfIsBodyTypeOrTypeParam();
    }
    if (this.state.carList !== prevState.carList) {
      this.updateIdList();
    }
  }

    handleResize = () => {
      this.setState({ width: window.innerWidth });
    };

  updateIdList = () => {
    const idList = removeDuplicatesByKeyFromArrayOfObject(this.state.carList, "AuctionId").map((car) => {
      return { carID: car.AuctionId, carLabel: car.MakeModel, carStatus: car.Status };
    });
    sessionStorage.setItem("CommonIds", JSON.stringify(idList));
  };

  scrollEventListenerToLoadMoreCars = () => {
    if (referenceToOffers && referenceToOffers.current) {
      if (referenceToOffers.current.scrollHeight < window.innerHeight + document.documentElement.scrollTop) {
        this.loadMoreCars();
      }
    }
  };

  // createNewCarLink(make, model) {
  //   return `?makes=${make}&models=${model}&status=Nowy&isCompany=true&financialMethod=all&selectedSortingValue=0`;
  // }

  getQueryParams = () => {
    const params = new URLSearchParams(this.props.location.search);
    const keyArr = [];
    for (const key of params.keys()) {
      keyArr.push(key);
    }
    const allowedKeys = [
      "fuelType",
      "makes",
      "models",
      "status",
      "isCompany",
      "financialMethod",
      "selectedSortingValue"
    ];

    if (!keyArr.every((el) => allowedKeys.includes(el))) {
      return undefined;
    }

    return {
      makes: params.get("makes"),
      models: params.get("models"),
      fuelType: params.get("fuelType"),
      status: params.get("status"),
      isCompany: params.get("isCompany"),
      financialMethod: params.get("financialMethod"),
      selectedSortingValue: params.get("selectedSortingValue")
    };
  };

  checkUrlConditions = () => {
    const { makes, models, fuelType, status, ...rest } = this.getQueryParams() || {};

    if (!this.props.location.search.includes("&isCompany=true&financialMethod=all&selectedSortingValue=0")) {
      return false;
    }

    if (!makes && !models && !fuelType && !status) {
      return false;
    }

    const makesCount = makes && makes.split(",").length;
    const modelsCount = models && models.split(",").length;
    const fuelsCount = fuelType && fuelType.split(",").length;
    const statusCount = status && status.split(",").length;
    const makeUrl = makes && makes.split(" ").join("-").toLowerCase();
    const modelUrl = models && models.split(" ").join("-").toLowerCase();

    if (makesCount > 1 || modelsCount > 1) {
      return false;
    }

    if (status && fuelType) {
      return false;
    }

    if (makesCount === 1 && fuelsCount === 1 && statusCount !== 1 && fuelType === "5") {
      return modelsCount === 1
        ? this.props.history.push(`/samochody-elektryczne/${makeUrl}-${modelUrl}`)
        : this.props.history.push(`/samochody-elektryczne/${makeUrl}`);
    } else if (makesCount === 1 && statusCount === 1 && fuelType !== 1) {
      return modelsCount === 1
        ? this.props.history.push(
            `/${status === "Nowy" ? "nowe-samochody" : "samochody-poleasingowe"}/${makeUrl}-${modelUrl}`
          )
        : this.props.history.push(`/${status === "Nowy" ? "nowe-samochody" : "samochody-poleasingowe"}/${makeUrl}`);
    } else if (makesCount === 1 && modelsCount === 1) {
      return this.props.history.push(`/marki/${makeUrl}-${modelUrl}`);
    } else {
      return false;
    }
  };

  redirectIfIsBodyTypeOrTypeParam() {
    // let chosenCarVariant = null;
    // // check if params fits multiple variants conf
    // for (let i = 0; i < this.props.multipleVariantsInfo.length; i++) {
    //   let item = this.props.multipleVariantsInfo[i];
    //   let url = this.createNewCarLink(item.Make, item.Model);
    //   if (url === this.props.location.search) {
    //     chosenCarVariant = item;
    //   }
    // }

    // ?makes=NISSAN&models=Qashqai&status=Nowy&isCompany=true&financialMethod=all&selectedSortingValue=0
    if (
      this.checkUrlConditions() &&
      this.props.location.search.includes("&isCompany=true&financialMethod=all&selectedSortingValue=0")
    ) {
      this.checkUrlConditions();
    } else {
      switch (this.props.location.search) {
        case this.state.filters &&
          `?promotions=${this.state.filters.promotions.join()}&isCompany=true&financialMethod=all&selectedSortingValue=0`:
          this.props.history.push("/promocje");
          break;
        case "?status=Nowy&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/nowe-samochody");
          break;
        case "?status=Poleasingowy&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/samochody-poleasingowe");
          break;
        case "?bodyType=3&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/sedan");
          break;
        case "?bodyType=1&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/hatchback");
          break;
        case "?bodyType=4&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/kombi");
          break;
        case "?bodyType=5&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/suv");
          break;
        case "?bodyType=9&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/bus");
          break;
        case "?bodyType=6&isCompany=true&financialMethod=all&selectedSortingValue=0":
          this.props.history.push("/van");
          break;
        default:
          /** @type [{name: string, link: string}] */
          const availableVehicleBrandLinks = this.props.avalibleMakesAndModels?.map((makeAndModels) => {
            return {
              name: makeAndModels.Make.Name.toUpperCase(),
              link: "/marki/" + makeAndModels.Make.Value.toLowerCase()
            };
          });

          for (let i = 0; i < availableVehicleBrandLinks.length; i++) {
            if (
              this.props.location.search ===
              `?makes=${availableVehicleBrandLinks[i].link
                .replace("/marki/", "")
                .toUpperCase()}&isCompany=true&financialMethod=all&selectedSortingValue=0`
            ) {
              this.props.history.push(availableVehicleBrandLinks[i].link);
              break;
            }
          }
          break;
      }
    }
  }

  createMakeAndModelsArray(filters) {
    const avalibleModelsNames = {};
    this.state.offerFiltersData.MakesAndModels.forEach((car) => {
      const modelArr = [];
      car.Models.forEach((model) => {
        modelArr.push(model.Name);
      });
      avalibleModelsNames[car.Make.Value] = modelArr;
    });

    const arraySchemaWithMakes =
      filters.makes &&
      filters.makes.map((make) => {
        return {
          Make: make,
          Models: avalibleModelsNames[make].filter((item) => filters.models.indexOf(item) > -1)
        };
      });

    return arraySchemaWithMakes;
  }

  mapFiltersObjectToApiSchema(filters, financialMethod, isCompany) {
    return {
      MakesAndModels: this.createMakeAndModelsArray(filters),
      MileageFrom: parseInt(filters.milleageFrom) || 0,
      MileageTo: parseInt(filters.milleageTo) || 0,
      YearFrom: parseInt(filters.productionFrom) || 0,
      YearTo: parseInt(filters.productionTo) || 0,
      BodyTypes: filters.bodyType,
      FuelTypes: filters.fuelType,
      GearBoxTypes: filters.gearBoxType,
      DoorsCount: filters.doorsCount,
      SeatsCount: filters.seatsCount,
      TotalPriceFrom: parseInt(filters.priceFrom) || 0,
      TotalPriceTo: parseInt(filters.priceTo) || 0,
      InstallmentFrom: parseInt(filters.installmentFrom) || 0,
      InstallmentTo: parseInt(filters.installmentTo) || 0,
      Locations: filters.location,
      PlateNumber: filters.plateNumber,
      HpFrom: parseInt(filters.powerFrom) || 0,
      HpTo: parseInt(filters.powerTo) || 0,
      CapacityFrom: parseInt(filters.capacityFrom) || 0,
      CapacityTo: parseInt(filters.capacityTo) || 0,
      Status: filters.status,
      isPerfect: filters && filters.status && !filters.status.includes("Poleasingowy"),
      SortType: 0,
      ResultsPerPage: 10000,
      isRent:
        financialMethod && financialMethod.includes("rent")
          ? true
          : financialMethod.includes("financing")
          ? true
          : false,
      isLeasing:
        financialMethod && financialMethod.includes("leasing")
          ? true
          : financialMethod.includes("financing")
          ? true
          : false,
      isCash: financialMethod && financialMethod.includes("cash"),
      isCompany: isCompany,
      SpecialPromotions: filters.promotions || []
    };
  }

  async searchCarsFromApi(filters, isCompany, financialMethod) {
    const filtersObject = this.mapFiltersObjectToApiSchema(filters, financialMethod, isCompany);
    this.setState({ isCompany: isCompany });
    let activeFiltersCount = 0;
    Object.values(filtersObject).forEach((el) => {
      if (el && el.length > 0) {
        activeFiltersCount++;
      }
    });
    localStorage.setItem("activeFiltersCount", activeFiltersCount);
    await this.getDataFromCorrectEndpoint(filtersObject).then((res) => {
      const carList = this.determinateCarListOnBaseOfStatus(filtersObject, financialMethod, res);

      this.carListWithoutSoring = [...carList];
      if (carList.length > 22) {
        carList
          .filter((x) => x && x.AuctionId)
          .sort((a, b) => a.Reservation - b.Reservation)
          .splice(22, 0, {});
      }

      this.setState({ carList: carList }, () => {
        this.setSortingValue();
        this.searchPreviousViewedCar();

        this.setState({
          searchedAllCarsCount: this.determinateCarsCountOnBaseOfStatus(filtersObject),
          currentFilters: filtersObject
        });
      });
    });
  }

  async setSortingValue() {
    const url = new URL(window.location.href);
    const selectedSortingValue = url.searchParams.get("selectedSortingValue");
    this.changeSelectSortByFromList(selectedSortingValue, this.state.carList);
    this.setState({
      selectedSortingValue: selectedSortingValue || "0"
    });
  }

  splitArrayIntoChunks(array, chunkSize) {
    if (array) {
      let splittedArray = array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      return splittedArray;
    } else {
      return [];
    }
  }

  determinateCarListOnBaseOfStatus(filtersObject, financialMethod, carListWithoutStatus) {
    let carList = [];
    if (
      filtersObject &&
      filtersObject.Status.length === 2 &&
      filtersObject.Status.includes("Nowy") &&
      filtersObject.Status.includes("Poleasingowy")
    ) {
      //we want to display it in order 3 new cars 3 afterLease cars, etc.
      let newCarsChunks = this.splitArrayIntoChunks(
        carListWithoutStatus.filter((car) => car.Reservation === false && car.Status === "Nowy"),
        3
      );
      let afterLeaseCarsChunks = this.splitArrayIntoChunks(
        carListWithoutStatus.filter((car) => car.Reservation === false && car.Status === "Poleasingowy"),
        3
      );
      let reservedCars = carListWithoutStatus.filter((car) => car.Reservation === true);
      let newCarsChunkTempIndex = 0;
      let afterLeaseCarsLeaseChunkTempIndex = 0;
      //shuffle chunks
      while (
        newCarsChunkTempIndex < newCarsChunks.length &&
        afterLeaseCarsLeaseChunkTempIndex < afterLeaseCarsChunks.length
      ) {
        carList.push(...newCarsChunks[newCarsChunkTempIndex]);
        carList.push(...afterLeaseCarsChunks[afterLeaseCarsLeaseChunkTempIndex]);
        newCarsChunkTempIndex++;
        afterLeaseCarsLeaseChunkTempIndex++;
      }

      while (
        newCarsChunkTempIndex < newCarsChunks.length &&
        afterLeaseCarsLeaseChunkTempIndex >= afterLeaseCarsChunks.length
      ) {
        carList.push(...newCarsChunks[newCarsChunkTempIndex]);
        newCarsChunkTempIndex++;
      }

      while (
        afterLeaseCarsLeaseChunkTempIndex < afterLeaseCarsChunks.length &&
        newCarsChunkTempIndex >= newCarsChunks.length
      ) {
        carList.push(...afterLeaseCarsChunks[afterLeaseCarsLeaseChunkTempIndex]);
        afterLeaseCarsLeaseChunkTempIndex++;
      }

      carList.push(...reservedCars);

      this.setState({ carStatus: null });
    }

    if (
      (filtersObject.Status.length === 3 &&
        filtersObject.Status.includes("Nowy") &&
        filtersObject.Status.includes("Poleasingowy")) ||
      filtersObject.Status.length === 0
    ) {
      //we want to display it in order 3 new cars, 1 auction car, 3 afterLease cars, etc.
      let newCarsChunks = this.splitArrayIntoChunks(
        carListWithoutStatus &&
          carListWithoutStatus.filter((car) => car.Reservation === false && car.Status === "Nowy"),
        3
      );
      let afterLeaseCarsChunks = this.splitArrayIntoChunks(
        carListWithoutStatus &&
          carListWithoutStatus.filter((car) => car.Reservation === false && car.Status === "Poleasingowy"),
        3
      );
      let reservedCars = carListWithoutStatus && carListWithoutStatus.filter((car) => car.Reservation === true);
      let newCarsChunkTempIndex = 0;
      let afterLeaseCarsLeaseChunkTempIndex = 0;
      //shuffle chunks

      while (
        newCarsChunkTempIndex < newCarsChunks.length &&
        afterLeaseCarsLeaseChunkTempIndex < afterLeaseCarsChunks.length
      ) {
        carList.push(...newCarsChunks[newCarsChunkTempIndex]);
        carList.push(...afterLeaseCarsChunks[afterLeaseCarsLeaseChunkTempIndex]);
        newCarsChunkTempIndex++;
        afterLeaseCarsLeaseChunkTempIndex++;
      }

      while (
        newCarsChunkTempIndex < newCarsChunks.length &&
        afterLeaseCarsLeaseChunkTempIndex >= afterLeaseCarsChunks.length
      ) {
        carList.push(...newCarsChunks[newCarsChunkTempIndex]);
        newCarsChunkTempIndex++;
      }

      while (
        afterLeaseCarsLeaseChunkTempIndex < afterLeaseCarsChunks.length &&
        newCarsChunkTempIndex >= newCarsChunks.length
      ) {
        carList.push(...afterLeaseCarsChunks[afterLeaseCarsLeaseChunkTempIndex]);
        afterLeaseCarsLeaseChunkTempIndex++;
      }

      carList.push(...reservedCars);
      this.setState({ carStatus: null });
    }

    if (filtersObject.Status.length === 1) {
      if (filtersObject.Status.includes("Nowy")) {
        carList = carList.concat(carListWithoutStatus);
        this.setState({ carStatus: "new" });
      }

      if (filtersObject.Status.includes("Poleasingowy")) {
        carList = carList.concat(carListWithoutStatus);
        this.setState({ carStatus: "afterLease" });
      }
    }

    return carList;
  }

  determinateCarsCountOnBaseOfStatus(filtersObject) {
    let carsCountWithoutNews = this.state.carList.filter((el) => el.Status && el.Status !== "Nowy").length;
    return filtersObject.Status.includes("Nowy") || filtersObject.Status.length === 0
      ? this.state.searchedNewCarsCount + carsCountWithoutNews
      : carsCountWithoutNews;
  }

  async getDataFromCorrectEndpoint(filtersObject) {
    let carsList;
    let newCarList = [];
    let leaseCarList = [];
    let auctionCarList = [];

    if (filtersObject.Status.includes("Nowy") || filtersObject.Status.length === 0) {
      await this.getNewVehiclesFromApi(filtersObject).then((res) => {
        newCarList = res;
      });
    }
    if (filtersObject.Status.includes("Poleasingowy") || filtersObject.Status.length === 0) {
      await this.getAfterLeaseVehiclesFromApi(filtersObject).then((res) => {
        leaseCarList = res;
      });
    }
    this.setState({ isLoadingCars: false });
    carsList = [...newCarList, ...leaseCarList, ...auctionCarList];
    return carsList;
  }

  searchPreviousViewedCar() {
    const previousViewedCarId = localStorage.getItem("previousViewedCarId");
    this.state.carList.forEach((el, index) => {
      if (el.AuctionId === parseInt(previousViewedCarId)) {
        const prevCarContainer =
          document.querySelector("#carId" + previousViewedCarId) ||
          document.querySelector("#carIdPromoted" + previousViewedCarId);
        if (prevCarContainer) {
          this.setState(
            {
              isPromotedPrev: prevCarContainer.id.includes("Promoted") ? true : false,
              carsDisplayFrom: prevCarContainer.id.includes("Promoted") ? 0 : this.state.carsDisplayFrom
            },
            () => {
              prevCarContainer.scrollIntoView({});

              localStorage.removeItem("previousViewedCarId");
            }
          );
        }
      }
    });
  }

  async getNewVehiclesFromApi(obj) {
    let newCarsList;
    await getNewVehiclesOffers(obj).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        newCarsList = response.data.Tails;
        this.setState({
          newCarsData: response.data.Tails,
          searchedNewCarsCount: response.data.Count
        });
      } else {
        endpointGuard(response.data.ErrorCode);
      }
    });
    return newCarsList;
  }

  async getAfterLeaseVehiclesFromApi(obj) {
    let afterLeaseCarsList;
    await getAfterLeaseVehiclesOffers(obj).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        afterLeaseCarsList = response.data.Tails;
        this.setState({
          afterLeaseCarsData: response.data.Tails,
          searchedALCarsCount: response.data.Count
        });
      } else {
        endpointGuard(response.data.ErrorCode);
      }
    });
    return afterLeaseCarsList;
  }

  checkCountOfAvalibleCarsBasedOnCurentFilters(filters, isComp, finM) {
    getOffersCount(this.mapFiltersObjectToApiSchema(filters, finM, isComp)).then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        this.setState({ countFilters: res.data });
      } else {
        endpointGuard(res.data.ErrorCode);
      }
    });
  }

  loadMoreCars() {
    let carsCount;

    if (this.state.carsCount + 18 > this.state.carList.length) {
      carsCount = this.state.carList.length;
    } else {
      carsCount = this.state.carsCount + 18;
    }
    this.setState({ carsCount: carsCount }, () => {
      localStorage.setItem("carsCount", this.state.carsCount);
    });
  }

  changeSelectSortByFromList(v) {
    this.setState({
      carList:
        parseInt(v) === 0 || parseInt(v) === null
          ? [...this.carListWithoutSoring]
          : changeSelectSortBy(v, this.state.carList)
              .filter((x) => x && x.AuctionId)
              .sort((a, b) => a.Reservation - b.Reservation),
      selectedSortingValue: v
    });
  }

  generateMakeModelsLinkForMultipleNewCars() {
    let links = [];
    for (let item of this.props.multipleVariantsInfo) {
      links.push(`/${item.Make.toLowerCase()}-${item.Model.toLowerCase()}`);
    }
    return links;
  }

  checkLinkIsForMultipleCar() {
    return this.generateMakeModelsLinkForMultipleNewCars().includes(this.props.location.pathname);
  }

  /**
   * Return SEO texts for brand links "/marki/x" and vehicle body type links (eg. "/van")
   * @returns {{mainTextLiterals: {mainTitle: string, mainText: string}, subTextLiterals: {subLiteralTitle: string, subLiteralText: string}[]}}
   */
  //  Seo texts for brand links "/marki/x" and vehicle body type links (eg. "/van")
  //  are from literals with naming convention like:
  //  FOR MARKS: SEO_MAIN_TITLE_MARKI_X, SEO_MAIN_TEXT_MARKI_X, SEO_SUBTITLE_1_MARKI_X, SEO_SUBTEXT_1_MARKI_X
  //  FOR BODY TYPES: SEO_MAIN_TITLE_X, SEO_MAIN_TEXT_X, SEO_SUBTITLE_1_X, SEO_SUBTEXT_1_X
  //  where X is proper brand or body type
  getDynamicSeoLiteralsForPathname() {
    if (this.state.literals) {
      const bodyTypeLinks = ["/sedan", "/hatchback", "/kombi", "/suv", "/bus", "/van"];
      let mainTextLiterals = { mainTitle: "", mainText: "" };
      let subTextLiterals = [];

      /**@type {string} equals '_MARKI_' or _BODYTYPE (eg. '_VAN') */
      let seoTypeKey = null;
      /**@type {string} equals brand (eg. 'BMW') or '' if it's bodyTypeSEO */
      let pathnameBrand = "";

      if (this.props.location.pathname.includes("/marki/")) {
        seoTypeKey = "_MARKI_";
        pathnameBrand = this.props.location.pathname
          .substring(this.props.location.pathname.indexOf("/marki/") + "/marki/".length)
          .split("-")[0]
          .toUpperCase();
        const multiPartNames = ["alfa-romeo", "land-rover", "mercedes-benz"];
        if (multiPartNames.some((name) => this.props.location.pathname.includes(name))) {
          pathnameBrand = this.props.location.pathname
            .substring(this.props.location.pathname.indexOf("/marki/") + "/marki/".length)
            .split("-")
            .slice(0, 2)
            .join("-")
            .toUpperCase();
        }
      } else if (bodyTypeLinks.includes(this.props.location.pathname)) {
        seoTypeKey = this.props.location.pathname.replace("/", "_").toUpperCase();
      }

      if (seoTypeKey) {
        let seoLiteralsKeys = Object.keys(this.state.literals).filter(
          (literalKey) =>
            literalKey.startsWith("SEO_") &&
            !literalKey.startsWith("SEO_META") &&
            literalKey.endsWith(seoTypeKey + pathnameBrand)
        );
        let mainTitleLiteralKey = seoLiteralsKeys.find((literalKey) =>
          literalKey.startsWith("SEO_MAIN_TITLE" + seoTypeKey)
        );
        let mainTextLiteralKey = seoLiteralsKeys.find((literalKey) =>
          literalKey.startsWith("SEO_MAIN_TEXT" + seoTypeKey)
        );
        let seoSubLiteralsKeys = seoLiteralsKeys.filter((literalKey) => literalKey.startsWith("SEO_SUB"));
        mainTextLiterals = {
          mainTitle: this.state.literals[mainTitleLiteralKey],
          mainText: this.state.literals[mainTextLiteralKey]
        };

        // We cannot iterate over literals and push them to array because there may be lack of some literal, moreover literals have no specified order
        // By collecting them into object by their index (number before "_MARKI_X" or "_VAN") we sort them and make sure that texts and titles won't mix with eachother
        let subLiteralObjects = {};
        seoSubLiteralsKeys.forEach((key) => {
          let subLiteralIndex = key[key.indexOf(seoTypeKey + pathnameBrand) - 1];
          if (Number.isInteger(Number.parseInt(subLiteralIndex))) {
            if (!subLiteralObjects[subLiteralIndex.toString()]) {
              subLiteralObjects[subLiteralIndex.toString()] = {};
            }

            if (key.startsWith("SEO_SUBTITLE")) {
              subLiteralObjects[subLiteralIndex.toString()]["subLiteralTitle"] = this.state.literals[key];
            } else if (key.startsWith("SEO_SUBTEXT")) {
              //we want to replace [HTMLNewLine] to <br/> in all seo sub texts to allow adding new lines
              subLiteralObjects[subLiteralIndex.toString()]["subLiteralText"] = this.state.literals[key]?.replaceAll(
                "[HTMLNewLine]",
                "<br/>"
              );
            }
          }
        });

        Object.keys(subLiteralObjects).forEach((subLiteralObjectKey) => {
          subTextLiterals.push(subLiteralObjects[subLiteralObjectKey]);
        });

        return {
          mainTextLiterals: mainTextLiterals,
          subTextLiterals: subTextLiterals
        };
      }
    }

    return {
      mainTextLiterals: {
        mainTitle: null,
        mainText: null
      },
      subTextLiterals: []
    };
  }

  /**
   * set proper for vehicle brand and vehicle body type links meta og:title, og:description and og:url
   */
  // FOR MARKS: SEO_META_TITLE_MARKI_X, SEO_META_DESCRIPTION_MARKI_X, SEO_META_URL_MARKI_X
  // FOR BODY TYPES: SEO_META_TITLE_X, SEO_META_DESCRIPTION_X, SEO_META_URL_X
  setDynamicMetaTagsFromLiterals() {
    //resets meta tags
    this.setDefaultMetaTags();
    if (this.state.literals) {
      const bodyTypeLinks = ["/sedan", "/hatchback", "/kombi", "/suv", "/bus", "/van"];
      /**@type {string} equals '_MARKI_' or _BODYTYPE (eg. '_VAN') */
      let seoTypeKey = null;
      /**@type {string} equals brand (eg. 'BMW') or '' if it's bodyTypeSEO */
      let pathnameBrand = "";

      if (this.props.location.pathname.includes("/marki/")) {
        seoTypeKey = "_MARKI_";
        pathnameBrand = this.props.location.pathname
          .substring(this.props.location.pathname.indexOf("/marki/") + "/marki/".length)
          .toUpperCase();
      } else if (bodyTypeLinks.includes(this.props.location.pathname)) {
        seoTypeKey = this.props.location.pathname.replace("/", "_").toUpperCase();
      }

      if (seoTypeKey) {
        let seoLiteralsKeys = Object.keys(this.state.literals).filter(
          (literalKey) => literalKey.startsWith("SEO_META") && literalKey.endsWith(seoTypeKey + pathnameBrand)
        );
        let metaSeoTitleKey = seoLiteralsKeys.find((literalKey) =>
          literalKey.startsWith("SEO_META_TITLE" + seoTypeKey)
        );
        let metaSeoDescriptionKey = seoLiteralsKeys.find((literalKey) =>
          literalKey.startsWith("SEO_META_DESCRIPTION" + seoTypeKey)
        );
        let metaSeoUrlKey = seoLiteralsKeys.find((literalKey) => literalKey.startsWith("SEO_META_URL" + seoTypeKey));
        const metaTitle = document.querySelector("meta[property='og:title']");
        const metaDescription = document.querySelector("meta[property='og:description']");
        const metaUrl = document.querySelector("meta[property='og:url']");

        if (metaTitle && metaSeoTitleKey) {
          metaTitle.setAttribute("content", this.state.literals[metaSeoTitleKey]);
        }

        if (metaDescription && metaSeoDescriptionKey) {
          metaDescription.setAttribute("content", this.state.literals[metaSeoDescriptionKey]);
        }

        if (metaUrl && metaSeoUrlKey) {
          metaUrl.setAttribute("content", this.state.literals[metaSeoUrlKey]);
        }
      }
    }
  }

  setDefaultMetaTags() {
    const metaTitle = document.querySelector("meta[property='og:title']");
    const metaDescription = document.querySelector("meta[property='og:description']");
    const metaUrl = document.querySelector("meta[property='og:url']");
    if (metaTitle) {
      metaTitle.setAttribute("content", "mAuto.pl – auta nowe i używane z atrakcyjnym finansowaniem.");
    }

    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Wybieraj z szerokiej oferty samochodów na mAuto.pl. Zrealizuj marzenia o własnym aucie! Skorzystaj z atrakcyjnego i bezpiecznego finansowania. Oferta dla firm. Niskie raty."
      );
    }

    if (metaUrl) {
      metaUrl.setAttribute("content", "https://mauto.pl");
    }
  }

  handleCarouselOnChange() {
    imitationClickForCloseAllTooltips();
  }

  render() {
    this.setDynamicMetaTagsFromLiterals();
    const carList = this.state.carList;
    const tileView = this.state.tileView;
    const dynamicSeoTexts = this.getDynamicSeoLiteralsForPathname();
    return (
      <MobileFiltersContext.Consumer>
        {({ isMobileFiltersOpen, toggleMobileFilters }) => {
          return (
            <>
              {this.state.offerFiltersData && (
                <ListBanner
                  isMobileFiltersOpen={isMobileFiltersOpen}
                  filters={this.state.offerFiltersData}
                  toggleMobileFilters={toggleMobileFilters}
                  selectedSortingValue={this.state.selectedSortingValue}
                  multipleVariantsInfo={this.props.multipleVariantsInfo}
                  setSelectedSortingValue={(v) => this.setState({ selectedSortingValue: v })}
                  searchCarsFromApi={(filters, iscomp, finMe) => this.searchCarsFromApi(filters, iscomp, finMe)}
                  countFilters={this.state.countFilters}
                  checkCountOfAvalibleCarsBasedOnCurentFilters={(filters, iscomp, finMe) =>
                    this.checkCountOfAvalibleCarsBasedOnCurentFilters(filters, iscomp, finMe)
                  }
                  foundedCarsCount={this.state.searchedAllCarsCount}
                  changeSelectSortBy={(x) => this.changeSelectSortByFromList(x)}
                />
              )}
              <div className="list-page-container">
                {this.props.location.search ? (
                  <Helmet>
                    <title>Lista ofert pojazdów | mAuto.pl</title>
                    <meta name="robots" content="noindex,nofollow"></meta>
                  </Helmet>
                ) : (
                  <ListPageHelmet
                    multipleVariantsInfo={this.props.multipleVariantsInfo}
                    isMultipleCarLink={this.checkLinkIsForMultipleCar()}
                  />
                )}
                <ContactIcons filters={this.state.currentFilters} />

                <div className="mobile-header">
                  <MobileHeaderTitle dynamicSeoTexts={dynamicSeoTexts} pathname={this.props.location.pathname} />
                </div>
                <div className="not-mobile-header">
                  <HeaderTextSection dynamicSeoTexts={dynamicSeoTexts} pathname={this.props.location.pathname} />
                </div>
                {this.state.isLoading && (
                  <div>
                    <LoadingSpinner />
                  </div>
                )}
                {!this.state.isLoadingCars ? (
                  <div className="offer-wrapper-container">
                    <div ref={referenceToOffers} className="offer-wrapper">
                      {carList &&
                        carList.length > 0 &&
                        carList.slice(this.state.carsDisplayFrom, this.state.carsCount).map((car, index) => {
                          return (
                            <>
                              <CarOfferTile data={car} isCompany={this.state.isCompany} key={`carOffer${index}`} />
                              {this.props.location.pathname === "/nowe-samochody" && (
                                <>
                                  {this.state.width > 1372 ? (
                                    <>
                                      {/* web */}
                                      {carList.length > 11 ? (
                                        <>{index === 11 && <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />}</>
                                      ) : (
                                        <>
                                          {index + 1 == carList.length && (
                                            <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                          )}
                                        </>
                                      )}

                                      {carList.length > 17 ? (
                                        <>
                                          {index !== 0 && (index + 1) % 17 === 0 && (
                                            <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {index + 1 == carList.length && (
                                            <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {this.state.width > 1023 ? (
                                        <>
                                          {/* tablet */}
                                          {carList.length > 7 ? (
                                            <>
                                              {index === 7 && <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />}
                                            </>
                                          ) : (
                                            <>
                                              {index + 1 == carList.length && (
                                                <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                              )}
                                            </>
                                          )}

                                          {carList.length > 11 ? (
                                            <>
                                              {index !== 0 && (index + 1) % 11 === 0 && (
                                                <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {index + 1 == carList.length && (
                                                <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {/* mobile */}
                                          {carList.length > 3 ? (
                                            <>
                                              {index === 3 && <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />}
                                            </>
                                          ) : (
                                            <>
                                              {index + 1 == carList.length && (
                                                <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                              )}
                                            </>
                                          )}

                                          {carList.length > 5 ? (
                                            <>
                                              {index !== 0 && (index + 1) % 5 === 0 && (
                                                <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {index + 1 == carList.length && (
                                                <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {/* specific make */}
                              {this.props.location.pathname.includes("/marki/") &&
                                !this.props.location.pathname.includes("/marki/tesla") && (
                                  <>
                                    {this.state.width > 1372 ? (
                                      <>
                                        {/* web */}
                                        {carList.length > 5 ? (
                                          <>{index === 5 && <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />}</>
                                        ) : (
                                          <>
                                            {index + 1 == carList.length && (
                                              <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                            )}
                                          </>
                                        )}

                                        {carList.length > 14 ? (
                                          <>
                                            {index !== 0 && (index + 1) % 14 === 0 && (
                                              <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {index + 1 == carList.length && (
                                              <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {this.state.width > 1023 ? (
                                          <>
                                            {/* tablet */}
                                            {carList.length > 3 ? (
                                              <>
                                                {index === 3 && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {index + 1 == carList.length && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                                )}
                                              </>
                                            )}

                                            {carList.length > 9 ? (
                                              <>
                                                {index !== 0 && (index + 1) % 9 === 0 && (
                                                  <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {index + 1 == carList.length && (
                                                  <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {/* mobile */}
                                            {carList.length > 1 ? (
                                              <>
                                                {index === 1 && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {index + 1 == carList.length && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                                                )}
                                              </>
                                            )}

                                            {carList.length > 4 ? (
                                              <>
                                                {index !== 0 && (index + 1) % 4 === 0 && (
                                                  <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {index + 1 == carList.length && (
                                                  <SpecificTile tileType={SpecificTilesEnum.FindBeyondTile} />
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              {/* tesla */}
                              {this.props.location.pathname.includes("/marki/tesla") && (
                                  <>
                                    {this.state.width > 1372 ? (
                                      <>
                                        {/* web */}
                                        {carList.length > 6 ? (
                                          <>{index === 5 && <SpecificTile tileType={SpecificTilesEnum.NoFindTeslaTile} />}</>
                                        ) : (
                                          <>
                                            {index + 1 == carList.length && (
                                              <SpecificTile tileType={SpecificTilesEnum.NoFindTeslaTile} />
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {this.state.width > 1023 ? (
                                          <>
                                            {/* tablet */}
                                            {carList.length > 3 ? (
                                              <>
                                                {index === 3 && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTeslaTile} />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {index + 1 == carList.length && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTeslaTile} />
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {/* mobile */}
                                            {carList.length > 1 ? (
                                              <>
                                                {index === 1 && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTeslaTile} />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {index + 1 == carList.length && (
                                                  <SpecificTile tileType={SpecificTilesEnum.NoFindTeslaTile} />
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          );
                        })}
                    {carList.length === 0 && JSON.stringify(this.state?.currentFilters?.Status) !== '["Aukcje"]' && (
                      <>
                      <SpecificTile tileType={SpecificTilesEnum.NoFindTile} />
                      <div className="no-cars-set-alert-section">
                        <Text
                          className="title"
                          as="h2"
                          fontSize={FontSize.SIZE_24}
                          lineHeight={LineHeight.HEIGHT_32}
                          paddingBottom="24px"
                        >
                          nie znalazłeś konkretnego samochodu?
                        </Text>
                        <Text
                          as="p"
                          className="content"
                          fontSize={FontSize.SIZE_16}
                          lineHeight={LineHeight.HEIGHT_24}
                        >
                          <Link to="/alert">
                            <Text
                              className="link"
                              as="span"
                              color={FontColor.endeavourBlueUI}
                            >
                              zapisz się na alert
                            </Text>
                          </Link>
                          , by ustawić powiadomienie o interesującym Cię aucie
                        </Text>
                      </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <LoadingSpinner />
              )}
              <div className="mleasing-main-container bg-gray without-max-width questions-section">
                <div className="content-wrapper">
                  { (carList.length > 0) && (<AdviseSection/>)}
                  <MainContactSection
                    literals={this.state.literals}
                    useClass="bg-white-tile-only list-main"
                    />
                </div>
              </div>
              <div className="mobile-header">
                <MobileHeaderTextSection 
                  dynamicSeoTexts = {dynamicSeoTexts}
                  pathname={this.props.location.pathname}
                />
              </div>
              {this.props.location.pathname === "/nowe-samochody" && <QuestionTextSectionNewCar />}
              {this.props.location.pathname === '/samochody-poleasingowe' && (
                <QuestionTextSectionLeaseCar />
              )}
              {this.props.location.pathname === '/samochody-elektryczne' && (
                <QuestionTextSectionElectricCar />
              )}
              {dynamicSeoTexts?.subTextLiterals.length > 0 && 
              <DynamicQuestionTextSection
                dynamicSeoTexts = {dynamicSeoTexts}
                pathname={this.props.location.pathname} 
                />
              }
            </div>
            </>
          );
        }}
      </MobileFiltersContext.Consumer>
    );
  }
}

export default withRouter(ListPage);
